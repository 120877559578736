import { Datatable } from '@swe/shared/ui-kit/components/datatable';
import { Column } from '@swe/shared/ui-kit/components/datatable/types';

import { formatPrice } from '@swe/shared/utils/price';
import { capitalize } from '@swe/shared/utils/string';

import { useMemo } from 'react';

import { LoyaltySystemLevel } from 'entities/loyalty/settings';

type DataSource = LoyaltySystemLevel & { placeholder?: unknown };

const COLUMNS: Column<DataSource>[] = [
  {
    name: 'placeholder',
    heading: '#',
    render: ({ rowIdx }) => rowIdx + 1,
    width: 'min-content',
  },
  {
    name: 'name',
    heading: 'Level',
    minWidth: 100,
    hAlign: 'left',
    render: ({ row }) => capitalize(row.name),
  },
  {
    name: 'bonus',
    heading: 'Bonus pts.',
    minWidth: 95,
    hAlign: 'right',
  },
  {
    name: 'goal',
    heading: 'Goal pts.',
    minWidth: 95,
    hAlign: 'right',
  },
  {
    name: 'moneyToBonus',
    heading: 'Pts. per $1 spent',
    minWidth: 155,
    hAlign: 'right',
  },
  {
    name: 'bonusToMoney',
    heading: 'Point value',
    minWidth: 190,
    hAlign: 'right',
    render: ({ row }) => formatPrice(row.bonusToMoney),
  },
];

type LevelsTableProps = {
  levels: LoyaltySystemLevel[];
  hideCurrency?: boolean;
};

const LevelsTable = ({ levels, hideCurrency }: LevelsTableProps) => {
  const columns = useMemo(() => {
    return COLUMNS.filter(({ name }) => {
      if (hideCurrency && name === 'bonusToMoney') {
        return false;
      }
      return true;
    });
  }, [hideCurrency]);

  return (
    <Datatable<DataSource>
      source={levels}
      columns={columns}
    />
  );
};

export { LevelsTable };
