import { usePaginated } from '@swe/shared/hooks/pagination';

import { Routes } from 'common/router/constants';
import GetLoyaltyTransactionsEndpoint from 'endpoints/loyalty/get-loyalty-transactions';

const useLoyaltyTransactions = () => {
  const { data: transactions, ...rest } = usePaginated({
    endpoint: GetLoyaltyTransactionsEndpoint,
    params: {},
    linkBuilderConfig: {
      pathname: Routes.ProfileLoyalty,
    },
  });

  return {
    transactions,
    ...rest,
  };
};

export { useLoyaltyTransactions };
