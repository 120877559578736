import Loader from '@swe/shared/ui-kit/components/loader';

import PlainHtml from '@swe/shared/ui-kit/components/plain-html';

import Stack from '@swe/shared/ui-kit/components/stack';

import { useStoreConfig } from '@swe/shop-ui/common/providers/config';
import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

import { useLoyalty } from 'common/use-cases/use-loyalty';
import { LevelsTable } from 'domains/profile/containers/loyalty/components/levels-table';
import { LoyaltyTransactionsHistory } from 'domains/profile/containers/loyalty/container/transactions-history';
import { Status } from 'entities/loyalty/info';

const Loyalty = () => {
  const { isRegistrationComplete } = useCurrentUser();
  const navigate = useRouterNavigate();
  const { isEnabled, settings, status, isLoading } = useLoyalty();
  const canHaveHistory = isRegistrationComplete && [Status.Member, Status.ExMember].includes(status!);

  const { hideLoyaltyCurrency } = useStoreConfig();

  if (isLoading) {
    return <Loader center />;
  }

  if (!isEnabled) {
    void navigate(Routes.Profile, { replace: true });

    return null;
  }

  return (
    <Stack spacing="md">
      {settings && settings.description && <PlainHtml html={settings.description} />}
      {settings && settings.levels && (
        <LevelsTable
          hideCurrency={hideLoyaltyCurrency}
          levels={settings.levels}
        />
      )}
      {canHaveHistory && <LoyaltyTransactionsHistory />}
    </Stack>
  );
};

export { Loyalty };
