import Link from '@swe/shared/providers/router/link';
import { Datatable } from '@swe/shared/ui-kit/components/datatable';
import { Column } from '@swe/shared/ui-kit/components/datatable/types';

import { ChevronRightIcon } from '@swe/shared/ui-kit/components/icon';

import { withSkeleton } from '@swe/shared/ui-kit/components/skeleton';

import Text from '@swe/shared/ui-kit/components/text';
import { formatDate } from '@swe/shared/utils/date';

import { formatPoints } from '@swe/shared/utils/points';
import cx from 'clsx';

import styles from './styles.module.scss';

import { Routes } from 'common/router/constants';
import { TransactionsListSkeleton } from 'domains/profile/containers/loyalty/components/transactions-history/skeleton';
import { LoyaltyTransaction } from 'entities/loyalty/transactions';

type SourceData = LoyaltyTransaction & {
  action?: undefined;
};

const COLUMNS: Column<SourceData>[] = [
  {
    name: 'payTime',
    heading: 'Date',
    width: 'min-content',
    render: ({ row: { payTime } }) => (
      <Text
        className={styles.paytime}
        variant="control"
      >
        {formatDate(payTime)}
      </Text>
    ),
  },
  {
    name: 'type',
    heading: 'Type',
    render: ({ row: { type } }) => (
      <Text
        className={styles.type}
        variant="control"
      >
        {type.name}
      </Text>
    ),
  },
  {
    name: 'amount',
    heading: 'Amount',
    width: 'min-content',
    render: ({ row: { amount } }) => {
      const isNegative = amount < 0;

      return (
        <Text
          className={cx(styles.amount, isNegative ? styles.amount_negative : styles.amount_positive)}
          variant="control"
        >
          {formatPoints(amount, { transaction: true })}
        </Text>
      );
    },
  },
  {
    name: 'action',
    heading: '',
    width: 'min-content',
    render: ({ row: { orderId } }) =>
      orderId ? (
        <Link href={{ pathname: Routes.ProfileOrderDetails, query: { id: String(orderId) } }}>
          <ChevronRightIcon />
        </Link>
      ) : null,
  },
];

type TransactionsHistoryProps = {
  transactions: LoyaltyTransaction[];
};

const TransactionsList = ({ transactions }: TransactionsHistoryProps) => {
  if (transactions.length === 0) {
    return <Text variant="control">No transactions yet</Text>;
  }

  return (
    <Datatable
      header={false}
      source={transactions}
      columns={COLUMNS}
    />
  );
};

const TransactionsListSkeletonized = withSkeleton(TransactionsList, TransactionsListSkeleton, ['transactions']);

export { TransactionsList, TransactionsListSkeletonized };
