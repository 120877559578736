import createModernEndpoint, { APIErrorExtended } from '@swe/shared/network/endpoint-factories/modern';

import { PaginatedRequest, PaginatedResponse } from '@swe/shared/types/wrappers/pagination';

import { LoyaltyTransaction } from 'entities/loyalty/transactions';

type Params = PaginatedRequest;
type Data = PaginatedResponse<LoyaltyTransaction>;
type Error = APIErrorExtended;

const NAME = '/Loyalty/GetLoyaltyTransfers';
const GetLoyaltyTransactionsEndpoint = createModernEndpoint<Params, Data, Error>(NAME);

export { GetLoyaltyTransactionsEndpoint };
export default GetLoyaltyTransactionsEndpoint;
