import { Pagination } from '@swe/shared/ui-kit/components/pagination';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';

import { TransactionsListSkeletonized } from 'domains/profile/containers/loyalty/components/transactions-history';
import { useLoyaltyTransactions } from 'domains/profile/use-cases/use-loyalty-transactions';

const LoyaltyTransactionsHistory = () => {
  const { transactions, pagination, pagesCount, total, buildPageLink, onPaginationChange } = useLoyaltyTransactions();

  return (
    <Stack spacing="xs">
      <SectionHeading>History</SectionHeading>
      <TransactionsListSkeletonized transactions={transactions?.list} />
      {pagesCount > 1 && (
        <Pagination
          currentPage={pagination.page}
          pageSize={pagination.pageSize}
          total={total}
          buildHref={buildPageLink}
          onChange={onPaginationChange}
        />
      )}
    </Stack>
  );
};

export { LoyaltyTransactionsHistory };
