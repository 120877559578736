import { Bone, Skeleton } from '@swe/shared/ui-kit/components/skeleton';

const TRANSACTION_HEIGHT_PX = 44;
const PADDING = 1;
const TOTAL_HEIGHT = TRANSACTION_HEIGHT_PX * 3 + PADDING * 2 * 2;

const TransactionSkeleton = ({ y }: { y: number }) => {
  return (
    <Bone
      x={0}
      y={y}
      width="100%"
      height={TRANSACTION_HEIGHT_PX}
    />
  );
};

const TransactionsListSkeleton = () => {
  return (
    <Skeleton
      uniqueKey="profile_document_list"
      height={TOTAL_HEIGHT}
    >
      <TransactionSkeleton y={0} />
      <TransactionSkeleton y={1 * TRANSACTION_HEIGHT_PX + 2 * PADDING} />
      <TransactionSkeleton y={2 * TRANSACTION_HEIGHT_PX + 4 * PADDING} />
    </Skeleton>
  );
};

export { TransactionsListSkeleton };
